@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

h1 {
  font-family: 'Italiana', sans-serif;
}
.container{
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
  font-family: 'Italiana', sans-serif;
}

.landing-spline {
  width: 100%;
  height: 100vh;
  background-color: black;
}

.titles{
  width: 92%;
  font-family: "Italiana", sans-serif;
  font-size: 78px;
  font-weight: 500;
  word-spacing: 30px;
  text-align: Left;
  color:white;
  padding:0.5rem;
}

.about-container{
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-around;
}
.about-text{
  font-family: "Inria Serif", serif;
}
.details{
  width: 45%;
  height: auto;
  font-family: "Jost", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
}
.about-image {
  width: 45%;
  height: auto;
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-image img{
  padding-top:6rem;
}
@media (max-width: 768px) {
  .about-container{
    display: flex;
    flex-direction: column;
  }
  .details {
    width: 80%;
  }
  .details p{
    font-size: 1rem;
  }
  .about-image{
    padding:0;
    margin: 0;
    width: 100%;
    height: 150px;
  }
  .about-image img{
    padding:0;
    margin: 0;
  }
}

.case-study {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100vh; /* Ensure space between items */
}

.text-content, .image-content {
  flex: 1;
}

.image-content {
  position: absolute;
  right: 0;
  width: 50%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

/*projects*/

.projects-section {
  padding: 2rem;
}

.section-title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.project-card {
  background-color: #333;
  padding: 1rem;
  border-radius: 8px;
}

.project-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.project-info h3 {
  margin-top: 1rem;
  font-size: 1.2rem;
}

.project-info p {
  font-size: 0.9rem;
  color: #ccc;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}

.modal-content {
  width: 90%;
  max-width: 800px;
  background: black;
  padding: 20px;
  border-radius: 15px; /* added curvature */
  position: relative;
  text-align: center;
}

.image-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.image-container::-webkit-scrollbar {
  display: none;
}

.modal-image {
  flex: 0 0 auto;
  width: 40%;
  scroll-snap-align: start;
}

.modal-title {
  letter-spacing: 3px; /* Adjusted letter spacing */
  margin-top: 20px;
}

.modal-description {
  margin-bottom: 20px;
  font-family: "Inria Serif", serif;
}

.close {
  display: inline-block;
  background: white;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px; /* Style close button with a white container */
}

@media (max-width: 768px) {
  .modal-content, .image-container {
    width: 95%;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  background-color: #FFD700;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-dot.active {
  background-color: #FFA500;
}
.scope-text {
  font-family: 'Segoe UI', sans-serif;
}

/*contact us*/
.contact-section{
  padding: 2rem;
}
.contact-title {
  color: #FFF;
  font-family: "Italiana", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 41.6px;
}
.contact-ways{
  display: flex;
  width:70%;
  justify-content: space-evenly;
}
.contact-item{
  display: flex;
  align-items: center;
}
.contact-item-text {
  color: #FFF;
  font-family: "Inria Serif", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0 0 0 0.5rem;
}
.center-section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-glass-card{
  width: 80%;
  min-height: 690px;
  display: flex;
  flex-direction: column;
  border-radius: 29px;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.20) 0%, rgba(115, 115, 115, 0.12) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 4rem;
  padding: 1rem 0 0 4rem;
}
.contact-glass-card label {
  color: #FFF;
  font-family: Italiana;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.48px;
  margin-top: 1rem;
}
.contact-glass-card input{
  width: 95%;
  height: 50px;
  border-radius: 29px;
  margin-top:0.5rem;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.20) 0%, rgba(115, 115, 115, 0.12) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
  font-size: 18px;
  padding-left: 1rem;
}
.contact-glass-card textarea{
  width: 95%;
  height: 200px;
  border-radius: 29px;
  margin-top:0.5rem;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.20) 0%, rgba(115, 115, 115, 0.12) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
  font-size: 18px;
  padding-left: 1rem
}
.contact-glass-card button{
  width:30%;
  height: 66px;
  color: #FFF;
  font-family: "Italiana", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 13.23px;
  border-radius: 29px;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.20) 0%, rgba(115, 115, 115, 0.12) 100%);
  margin-top:2rem;
}
.contact-glass-card button:hover{
  box-shadow: 0px 1px 27.6px 3px #FFB801;
}

@media (max-width: 768px) {
  .contact-title {
    font-weight: 800 !important;
    text-align: Left;
    color:white;
  }
  .contact-ways {
    flex-direction: column;
  }

  .contact-item {
    justify-content: flex-start;
    width: 100%;
    margin: 5px 0;
  }

  .contact-item-text {
    text-align: center;
  }
  .contact-title {
    color: #FFF;
    font-family: "Italiana", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 21.6px;
  }
  .contact-glass-card{
    padding: 1rem;
    min-height: 490px;
  }
  .contact-glass-card input{
    width: 85%;
    height: 30px;
    border-radius: 29px;
    margin-top:0.5rem;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.20) 0%, rgba(115, 115, 115, 0.12) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 18px;
    padding-left: 1rem;
  }
  .contact-glass-card label {
    color: #FFF;
    font-family: Italiana;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 6.48px;
    margin-top: 1rem;
  }
  .contact-glass-card textarea{
    width: 95%;
    height: 150px;
    border-radius: 29px;
    margin-top:0.5rem;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.20) 0%, rgba(115, 115, 115, 0.12) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 18px;
    padding-left: 1rem
  }
  .contact-glass-card button{
    width:40%;
    height: 46px;
    color: #FFF;
    font-family: "Italiana", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 13.23px;
    border-radius: 29px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.20) 0%, rgba(115, 115, 115, 0.12) 100%);
    margin-top:2rem;
  }
  .contact-item-text{
    font-size: 16px;
  }
}
